/* eslint-disable react/jsx-filename-extension */
import {
    Hero, SEO, Waistband, Modal, Log, PegasusClient,
} from '@brandedholdings/react-components';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import * as queryString from '@brandedholdings/query-string';
import { merge } from 'lodash';
import PagePost from '../../models/PagePost';
import { mediaNet as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import ResultsForm from '../../components/ResultsForm';
import { InterlincxContainerV2 } from '../../components/Interlincx/InterlincxContainerV2';
import { InterlincxFmx2 } from '../../components/Interlincx/InterlincxFmx2';
import PrepopulatedFields from '../../models/PrepopulatedFields';

class FmxTwo extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            modalIsOpen: false,
            clickedElement: null,
            modalEvaluated: false,
        };
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    onModalFormSubmit = () => {
        const { clickedElement } = this.state;
        console.log('clickedElement', clickedElement);
        clickedElement.click();
        this.setState({ modalEvaluated: true, modalIsOpen: false });
    };

    onInterlincxClick = (e) => {
        const { clickedElement } = this.state;
        if (clickedElement == null) {
            e.preventDefault();
            console.log('e', e, this.modalRef);
            if (PrepopulatedFields.isPrepopulated(['primaryPhone'])) {
                const fields = PrepopulatedFields.mapFields();
                const { primaryPhone } = fields;
                new PegasusClient(siteMeta).getIsSubscribed(primaryPhone, siteMeta.optInEntityId).then(
                    (res) => {
                        if (res.data.is_subscribed === true) {
                            this.setState({ clickedElement: e.target });
                            this.onModalFormSubmit();
                        } else {
                            this.setState({ clickedElement: e.target, modalIsOpen: true });
                        }
                    }, () => {
                        this.setState({ clickedElement: e.target, modalIsOpen: true });
                    }
                );
            }
            this.setState({ clickedElement: e.target, modalIsOpen: true });
        }
    };

    render() {
        const loc = this.props.location;
        const parsed = queryString.parse(loc.search);
        const {
            zone,
            email,
            name_first: nameFirst,
            name_last: nameLast,
            address_state: geoState,
            military: mil = 'false',
            sub: s2,
            source_domain: sourceDomain,
            source_promo_id: s1,
        } = parsed;
        const military = mil.toLowerCase();
        const subCode = s2;
        const extraParams = {
            params: {
                email,
                nameFirst,
                nameLast,
                geoState,
                military,
                s2,
                subCode,
                sourceDomain,
                s1,
            },
        };

        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const { template } = pageMeta;
        const { modalIsOpen } = this.state;

        return (
            <PageWrapper location={this.props.location} templateType={template} adserve>
                <SEO post={post} />
                <Hero
                    heading="Great News!"
                    subheading="Personal loan options are available!"
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--arrow"
                    actionModifiers={null}
                />
                <Waistband>
                    <div className="layout">
                        <div id="results-container">
                            <InterlincxContainerV2 onInterlincxClick={this.onInterlincxClick} useRawConfig>
                                {({ interlincxConfig }) => {
                                    const mergedConfig = merge(interlincxConfig, extraParams);
                                    return <InterlincxFmx2 onInterlincxClick={this.onInterlincxClick} config={mergedConfig} zone={zone || 'default'} />;
                                }}
                            </InterlincxContainerV2>
                        </div>
                    </div>
                </Waistband>
                <Modal
                    isModalOpen={modalIsOpen}
                    heading={false}
                    triggerOnClick
                    className="form-modal"
                    onClose={this.onModalFormSubmit}
                    ref={this.modalRef}
                >
                    <ResultsForm onSubmit={this.onModalFormSubmit} onCloseModal={this.onModalFormSubmit} />
                </Modal>
            </PageWrapper>
        );
    }
}

FmxTwo.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

export default FmxTwo;
